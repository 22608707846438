@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
// @import url("./theme.less");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
:root {
  --body-background: @body-background;
  --primary-color: #f9a826;
  --success-color: @success-color;
  --green-color: @success-color;
  --danger-color: rgb(228, 82, 82);
  --yellow-color: @yellow-base;
  --modal-header-bg: @modal-header-bg;
  --border-radius-base: 10px;
  --input-border-radius-base: 15px;
}

* {
  font-family: "Quicksand", sans-serif;
}
