@import "~ng-zorro-antd/ng-zorro-antd.less";

@primary-color: #f9a826;

.ant-btn,
.ant-tag {
  border-radius: 8px !important;
}

.ant-input,
.ant-select-selector,
.ant-form-item-control-input,
.ant-picker,
.ant-input-number,
.ant-input-affix-wrapper {
  border-radius: 8px !important;
}


@media (max-width:650px){

  .ant-row {
    display: block;
  }
}

.ant-pagination-item,
.ant-pagination-item-link {
  border: none !important;
}

.ant-pagination-item-link {
  font-size: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-pagination ul {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
}

li.ant-pagination-options {
  .ant-select {
    border: none !important;
  }
}
